<template>
  <div id="app" align="center">

    <h1>此功能尚在开发!</h1>
    <el-container>
      <div class="main-pic" align="middle" style="margin:auto">
        <img src="https://r.photo.store.qq.com/psc?/V53KcXfb1umonn4HbITu3rINxs43TczD/45NBuzDIW489QBoVep5mcdt1OksodJaq5.VcMVCBrgaAxYRcKt0fGtLhRy4vkDUZqWw628vq0ZZ75W2JayRAEWThHvTsKEIx9ayTg*ANCSA!/r" class="main-pic" width="400" height="400" align="middle">
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Comments'
}
</script>

<style scoped>

</style>
